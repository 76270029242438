import React from "react"
import ReactMarkdown from "react-markdown"
import "tachyons-sass/tachyons.scss"

const Publication = props => {
  return (
    <section className="fw2 center lh-prose pv1">
      <h3 className="fw1">{props.title}</h3>
      <ReactMarkdown children={props.authors}/>
      <p>{props.venue}</p>
      <p>{props.doiarxiv} <a href={props.url}>{props.displayUrl}</a></p>
    </section>
  )
}

export default Publication