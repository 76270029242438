import React from "react"
import ReactMarkdown from "react-markdown"
import "tachyons-sass/tachyons.scss"

const Project = props => {
  return (
    <section className="fw2 pv1">
      <h3 className="fw1" style={{display: "inline"}}>{props.title}</h3> ({props.year})
      <ReactMarkdown children={props.authors}/>
      <p><a href={props.url}>{props.displayUrl}</a></p>
    </section>
  )
}

export default Project